import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="431" height="79" viewBox="0 0 431 79" fill="none" {...props}>
      <g clip-path="url(#clip0_1654_11985)">
        <path d="M45.3355 19.6281V32.7177L34 26.1708L45.3355 19.6281Z" fill="black" />
        <path d="M45.3355 32.7177V45.8031L34 39.2604L45.3355 32.7177Z" fill="black" />
        <path d="M22.6673 32.7177V45.8031L11.334 39.2604L22.6673 32.7177Z" fill="black" />
        <path d="M45.3355 45.8031L34 52.3458V39.2604L45.3355 45.8031Z" fill="black" />
        <path d="M33.9994 39.2604V52.3458L22.666 45.8031L33.9994 39.2604Z" fill="black" />
        <path d="M33.9994 13.0854V26.1708L22.666 19.6281L33.9994 13.0854Z" fill="black" />
        <path d="M45.3355 19.6281L34 26.1708V13.0854L45.3355 19.6281Z" fill="black" />
        <path d="M33.9994 26.1708L22.666 32.7177V19.6281L33.9994 26.1708Z" fill="black" />
        <path d="M56.6671 39.2604L45.3359 45.8031V32.7177L56.6671 39.2604Z" fill="black" />
        <path d="M56.6671 26.1708L45.3359 32.7177V19.6281L56.6671 26.1708Z" fill="black" />
        <path d="M33.9994 39.2604L22.666 45.8031V32.7177L33.9994 39.2604Z" fill="black" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.9994 39.2604V26.1708L22.666 32.7177L33.9994 39.2604ZM34 39.2604L45.3355 32.7177L34 26.1708V39.2604Z"
          fill="#FFCC47"
        />
        <path d="M56.6671 65.4311L45.3359 71.9737V58.8884L56.6671 65.4311Z" fill="black" />
        <path d="M45.3355 58.8884V71.9737L34 65.4311L45.3355 58.8884Z" fill="black" />
        <path d="M22.6673 19.6281V32.7177L11.334 26.1708L22.6673 19.6281Z" fill="black" />
        <path d="M33.9994 52.3457V65.4311L22.666 58.8884L33.9994 52.3457Z" fill="black" />
        <path d="M33.9994 65.4311L22.666 71.9737V58.8884L33.9994 65.4311Z" fill="black" />
        <path d="M22.6673 32.7177L11.334 39.2604V26.1708L22.6673 32.7177Z" fill="black" />
        <path d="M22.6673 45.8031L11.334 52.3458V39.2604L22.6673 45.8031Z" fill="black" />
        <path d="M45.3355 58.8884L34 65.4311V52.3457L45.3355 58.8884Z" fill="black" />
        <path d="M56.6671 39.2604V52.3458L45.3359 45.8031L56.6671 39.2604Z" fill="black" />
        <path d="M56.6671 26.1708V39.2604L45.3359 32.7177L56.6671 26.1708Z" fill="black" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M34 0.000106812L45.3355 6.54277V19.6281L34 13.0854V0.000106812ZM33.9994 13.0854L22.6673 19.6274V19.6281L11.334 26.1708V13.0854L22.666 6.54352V6.54277L33.9994 0.000106812V13.0854ZM22.6673 58.8877L33.9994 52.3457L22.6673 45.8038V45.8031L22.6667 45.8034L22.666 45.8031V45.8038L11.334 52.3457V65.4311L22.6673 58.8884V58.8877ZM56.666 65.4311L56.6666 65.4307L56.6671 65.4311V65.4304L67.9994 58.8884V45.8031V32.7177V19.6281L56.6671 13.0861V13.0854L45.3359 6.54277V19.6281L56.666 26.1701V26.1708V39.2604V52.3451L45.3359 45.8031V58.8884L56.666 65.4304V65.4311ZM11.3333 26.1708V39.2604V52.3457V65.4311L0 58.8884V45.8031V32.7177V19.6281L11.3333 13.0854V26.1708ZM45.3355 71.9737L34 78.5164V65.4311L45.3355 71.9737ZM45.3355 45.8031V58.8884L34 52.3457L45.3355 45.8031ZM22.666 71.9737L33.9994 78.5164V65.4311L22.666 71.9737Z"
          fill="white"
        />
        <path d="M22.6673 58.8884V71.9737L11.334 65.4311L22.6673 58.8884Z" fill="black" />
      </g>
      <path d="M408.653 24.6882H395.695V17.5582H430.043V24.6882H417.085V60.9582H408.653V24.6882Z" fill="white" />
      <path
        d="M357.852 53.8282V24.6882L364.982 17.5582H386.186L393.192 24.5642V31.1982H384.76V27.6642L381.846 24.7502H369.446L366.284 27.9122V50.6042L369.446 53.7662H381.846L384.76 50.8522V47.3182H393.192V53.9522L386.186 60.9582H364.982L357.852 53.8282Z"
        fill="white"
      />
      <path
        d="M331.45 17.5582H339.138L355.01 60.9582H346.454L342.92 51.3482H327.668L324.134 60.9582H315.578L331.45 17.5582ZM341.06 44.4042L335.294 27.9122H335.17L329.59 44.4042H341.06Z"
        fill="white"
      />
      <path
        d="M311.559 46.5742V60.9582H303.127V48.4962L299.407 44.3422H285.891V60.9582H277.459V17.5582H304.677L311.311 24.2542V37.3982L307.095 41.6762L311.559 46.5742ZM285.891 37.3982H300.833L303.003 35.2282V26.7962L300.833 24.6262H285.891V37.3982Z"
        fill="white"
      />
      <path
        d="M239.859 17.5582H271.231V24.6882H248.291V35.6622H269.433V42.6682H248.291V53.8282H271.231V60.9582H239.859V17.5582Z"
        fill="white"
      />
      <path
        d="M199.453 54.2622V48.0622H207.761V51.7202L209.807 53.7662H222.641L224.749 51.6582V44.4662L222.703 42.4202H206.273L199.577 35.7242V24.2542L206.273 17.5582H225.865L232.561 24.2542V30.5162H224.253V26.7962L222.207 24.7502H209.931L207.885 26.7962V33.1822L209.931 35.2282H226.361L233.057 41.9242V54.1382L226.237 60.9582H206.149L199.453 54.2622Z"
        fill="white"
      />
      <path
        d="M159.977 54.2622V48.0622H168.285V51.7202L170.331 53.7662H183.165L185.273 51.6582V44.4662L183.227 42.4202H166.797L160.101 35.7242V24.2542L166.797 17.5582H186.389L193.085 24.2542V30.5162H184.777V26.7962L182.731 24.7502H170.455L168.409 26.7962V33.1822L170.455 35.2282H186.885L193.581 41.9242V54.1382L186.761 60.9582H166.673L159.977 54.2622Z"
        fill="white"
      />
      <path
        d="M123.307 17.5582H154.679V24.6882H131.739V35.6622H152.881V42.6682H131.739V53.8282H154.679V60.9582H123.307V17.5582Z"
        fill="white"
      />
      <path d="M96.958 24.6882H84V17.5582H118.348V24.6882H105.39V60.9582H96.958V24.6882Z" fill="white" />
      <defs>
        <clipPath id="clip0_1654_11985">
          <rect width="68" height="78.5163" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Logo;
